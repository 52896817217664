import { Box, Button, makeStyles, Paper } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import routes from '../../api/routes';
import { CardContainer } from '../../components/Cards';
import DatePicker from '../../components/form/DatePicker';
import ReturnButton from '../../components/form/ReturnButton';
import AgeStats from '../../components/missions/AgeStats';
import HourlyStats from '../../components/missions/HourlyStats';
import MissionComments from '../../components/missions/MissionComments';
import Dashboard1 from '../../components/missions/MissionDashboard1';
import Dashboard2 from '../../components/missions/MissionDashboard2';
import PrimaryButton from '../../components/PrimaryButton';
import Title from '../../components/Title';
import { AssociationFiltersDto } from '../../dto/association';
import { MissionWithAssociationDto } from '../../dto/mission';
import { MissionReportsOnRecruiterDto } from '../../dto/missionReport';
import { MissionUserDto, UserDto } from '../../dto/user';
import useFetch from '../../hooks/useFetch';
import MissionDatas from '../../components/missions/MissionDatas';
import FilterWeeks from '../../components/FilterWeeks';
import { MissionOverviewChart } from '../../components/missions/MissionOverviewChart';
import { sortUsersMission } from './MissionDetails';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { DashboardObjectivesRD } from '../../components/missions/MissionDashboardObjectives';

const UserMissionDetails = () => {
	const history = useHistory();
	const { id, userId } = useParams<{ id: string; userId: string }>();
	const [, user] = useFetch<UserDto>(routes.users.get({ id: userId }), [userId]);
	const [, mission] = useFetch<MissionWithAssociationDto>(routes.missions.get({ id: +id }));
	const [openDashboard2, setDashboard2] = useState(false);

	const [users, setUsers] = useState<MissionUserDto[]>([]);
	useFetch<MissionUserDto[]>(routes.missions.users.all({ id }), [], (data) =>
		setUsers(sortUsersMission(data)),
	);
	const [previousUserId, setPreviousUserId] = useState(0);
	const [previousUserName, setPreviousUserName] = useState('');
	const [nextUserId, setNextUserId] = useState(0);
	const [nextUserName, setNextUserName] = useState('');

	useEffect(() => {
		if (users.length) {
			const index = users.findIndex((u) => +u.id === +userId);
			const len = users.length;
			if (index !== 0) {
				setPreviousUserId(+users[index - 1].id);
				setPreviousUserName(users[index - 1].firstName + ' ' + users[index - 1].lastName);
			} else {
				setPreviousUserId(+users[len - 1].id);
				setPreviousUserName(users[len - 1].firstName + ' ' + users[len - 1].lastName);
			}
			if (index !== len - 1) {
				setNextUserId(+users[index + 1].id);
				setNextUserName(users[index + 1].firstName + ' ' + users[index + 1].lastName);
			} else {
				setNextUserId(+users[0].id);
				setNextUserName(users[0].firstName + ' ' + users[0].lastName);
			}
		}
	}, [users, userId]);

	const [periodStart, setPeriodStart] = useState('');
	const [periodEnd, setPeriodEnd] = useState('');
	const filters = new AssociationFiltersDto();
	filters.recruiterId = userId;
	filters.missionId = id;
	filters.periodStart = periodStart ? moment(periodStart).format('YYYY-MM-DD 00:00:00') : '';
	filters.periodEnd = periodEnd ? moment(periodEnd).format('YYYY-MM-DD 23:59:59') : '';
	filters.minimumDonation = 0;

	const [, reports] = useFetch<MissionReportsOnRecruiterDto>(
		routes.missions.reports.recruiterReports({ id }, { recruiterId: userId }),
		[userId],
	);

	const classes = useStyles({ border: false });

	return (
		<Box px={4} py={0} position="relative" mb={8}>
			<>
				<Box style={{ position: 'relative', width: '100%' }}>
					<Box display="flex" justifyContent="space-between" flexWrap="wrap" alignItems="center">
						<ReturnButton to={`/missions/${id}`}>Retour à la mission {mission?.tricoCode}</ReturnButton>
					</Box>

					<Box display="flex" style={{gap: "1%"}} mx={-1} padding="8px">
						<DatePicker
							format="DD/MM/YYYY"
							label="Début de la période"
							value={periodStart}
							onChange={(date) => setPeriodStart(date)}
						/>
						<DatePicker
							format="DD/MM/YYYY"
							label="Fin de la période"
							value={periodEnd}
							onChange={(date) => setPeriodEnd(date)}
						/>
						<FilterWeeks
							setPeriodStart={setPeriodStart}
							startDate={mission?.startDate}
							endDate={mission?.endDate}
							setPeriodEnd={setPeriodEnd}
						/>
					</Box>
				</Box>
			</>

			<Box display="flex" justifyContent="space-between" mt={2}>
				<Button
					onClick={() => history.push(`/missions/${id}/recruteur/${previousUserId}`)}
					style={{ fontSize: '0.8em', color: '#3F51B5', paddingLeft: 0, margin: '10px -3px' }}
				>
					<ChevronLeft style={{ fontSize: '24px' }} />
					{previousUserName}
				</Button>
				<Box display="flex" flexDirection="column" alignItems="center">
					<Box
						style={{
							borderRadius: 3,
							height: 100,
							width: 100,
							background: `#DDD center / cover no-repeat url(${user?.avatar?.path})`,
						}}
					/>
					<Box mt={1} p={0.5}>
						{user?.firstName} {user?.lastName}
					</Box>
				</Box>
				<Button
					onClick={() => history.push(`/missions/${id}/recruteur/${nextUserId}`)}
					style={{ fontSize: '0.8em', color: '#3F51B5', paddingRight: 0, margin: '10px -3px' }}
				>
					{nextUserName}
					<ChevronRight style={{ fontSize: '24px' }} />
				</Button>
			</Box>

			<DashboardObjectivesRD missionId={+id} mission={mission} filters={filters}/>
			<Title mt={3}>Tableau de bord</Title>
			<CardContainer>
				<Dashboard1 filters={filters} />
			</CardContainer>
			<Box display="flex" justifyContent="end" mb={2}>
				<PrimaryButton onClick={() => setDashboard2(!openDashboard2)}>
					{openDashboard2 ? '-' : '+'}
				</PrimaryButton>
			</Box>
			{openDashboard2 && (
				<CardContainer>
					<Dashboard2 filters={filters} user={user} />
				</CardContainer>
			)}
			<MissionOverviewChart missionId={id} userId={userId} />
			<Box style={{ position: 'relative', width: '100%' }}>
				<Box display="flex" flexWrap="wrap" width="100%" style={{ margin: -5 }}>
					<Paper variant="outlined" className={classes.paper}>
						<Title>Répartition des âges</Title>
						<Box width="80vw" maxWidth={400} boxSizing="border-box" flexGrow={1}>
							<AgeStats
								missionId={id}
								recruiterId={userId}
								periodStart={periodStart}
								periodEnd={periodEnd}
							/>
						</Box>
					</Paper>
					<Paper variant="outlined" className={classes.paper}>
						<Title>Production horaire</Title>
						<Box width="80vw" maxWidth={400} display="flex" flexGrow={1} alignItems="center">
							<HourlyStats missionId={id} recruiterId={userId} />
						</Box>
					</Paper>
				</Box>
			</Box>
			<Title mt={3}>Commentaires de mission</Title>
			<MissionComments
				midMissionComment={reports?.mid?.comment}
				endMissionComment={reports?.end?.comment}
			/>
			<CardContainer display="flex">
				{mission && (
					<MissionDatas
						mission={mission}
						user={user}
						filters={filters}
						userId={userId}
					/>
				)}
			</CardContainer>
		</Box>
	);
};

const useStyles = makeStyles({
	paper: {
		margin: 5,
		padding: '20px 10px',
		flexGrow: 1,
		display: 'flex',
		alignItems: 'center',
		flexFlow: 'column',
		boxSizing: 'border-box',
	},
});

export default UserMissionDetails;
