import React from 'react';
import { Box, Paper } from "@material-ui/core";
import Title from "../Title";
import routes from '../../api/routes';
import PrimaryButton from '../PrimaryButton';
import SendIcon from '@material-ui/icons/Send';
import useForm from '../../hooks/useForm';
import SubmitButton from '../form/SubmitButton';
import ErrorMessage from '../form/ErrorMessage';
import { ObjectivesDto } from '../../dto/objectives';
import NumberInput from '../form/NumberInput';

const ObjectivesForm = ({ onClose, missionId, objectives }: { onClose: () => any, missionId?: string, objectives: ObjectivesDto }) => {
	const form = useForm({
		init: {
			missionId: +missionId,
			//Mission
			streetHours: objectives?.streetHours,
			completedDonations: objectives?.completedDonations,
			averageDonation: objectives?.averageDonation,
			medianAge: objectives?.medianAge,
			stopPAPercent: objectives?.stopPAPercent,
			//RE
			streetHoursRE: objectives?.streetHoursRE,
			completedDonationsRE: objectives?.completedDonationsRE,
			medianDonationRE: objectives?.medianDonationRE,
			medianAgeRE: objectives?.medianAgeRE,
			stopPAPercentRE: objectives?.stopPAPercentRE,
			//RD
			realRateRD: objectives?.realRateRD,
			medianDonationRD: objectives?.medianDonationRD,
			medianAgeRD: objectives?.medianAgeRD,
			between18And30PercentRD: objectives?.between18And30PercentRD,
			stopPAPercentRD: objectives?.stopPAPercentRD
		} as ObjectivesDto,
		url: routes.objectives.missions.createOrUpdateObjectives()
	});

	return <form 
		noValidate 
		onSubmit={
			e => {
				e.preventDefault();
				form.submit();
			}
		}
		style={{ width: "min(450px, 80vw)" 
	}}>
		<Title mb={2}>Renseigner les objectifs</Title>
		{form.errors?.global && <ErrorMessage>{form.errors?.global}</ErrorMessage>}
		{form.success ? <Box>
			<Box mb={2}>
				<Title small color="#44BB44">Les objectifs ont bien été envoyés !</Title>
			</Box>
			<Box mt={1} display="flex" justifyContent="end">
				<PrimaryButton onClick={onClose}>Fermer</PrimaryButton>
			</Box>
		</Box> :
			<Box>
				<Paper variant="outlined" style={{padding: "16px", marginTop: "16px"}}>
					<Title big color="#44BB44">Mission</Title>
					<Box mb={2}>
						<NumberInput fullWidth label="Heures rue (optionnel)" {...form.field('streetHours')} />
					</Box>
					<Box mb={2}>
						<NumberInput fullWidth label="BS réel (optionnel)" {...form.field('completedDonations')} />
					</Box>
					<Box mb={2}>
						<NumberInput required isFloat fullWidth label="Don Moyen" {...form.field('averageDonation')} />
					</Box>
					<Box mb={2}>
						<NumberInput required isFloat fullWidth label="Âge médian" {...form.field('medianAge')} />
					</Box>
					<Box mb={2}>
						<NumberInput required isFloat fullWidth label="% Stop PA" {...form.field('stopPAPercent')} />
					</Box>
				</Paper>
				<Paper variant="outlined" style={{padding: "16px", marginTop: "16px"}}>
					<Title big color="#44BB44">Responsable d'équipe</Title>
					<Box mb={2}>
						<NumberInput required fullWidth label="Heures rue" {...form.field('streetHoursRE')} />
					</Box>
					<Box mb={2}>
						<NumberInput required fullWidth label="BS réel" {...form.field('completedDonationsRE')} />
					</Box>
					<Box mb={2}>
						<NumberInput required isFloat fullWidth label="Don médian" {...form.field('medianDonationRE')} />
					</Box>
					<Box mb={2}>
						<NumberInput required isFloat fullWidth label="Âge médian" {...form.field('medianAgeRE')} />
					</Box>
					<Box mb={2}>
						<NumberInput required isFloat fullWidth label="% Stop PA" {...form.field('stopPAPercentRE')} />
					</Box>
				</Paper>
				<Paper variant="outlined" style={{padding: "16px", marginTop: "16px"}}>
					<Title big color="#44BB44">Recruteurs donateurs</Title>
					<Box mb={2}>
						<NumberInput required fullWidth label="Taux réel" {...form.field('realRateRD')} />
					</Box>
					<Box mb={2}>
						<NumberInput required isFloat fullWidth label="Don médian" {...form.field('medianDonationRD')} />
					</Box>
					<Box mb={2}>
						<NumberInput required isFloat fullWidth label="Âge médian" {...form.field('medianAgeRD')} />
					</Box>
					<Box mb={2}>
						<NumberInput required isFloat fullWidth label="% 18-30" {...form.field('between18And30PercentRD')} />
					</Box>
					<Box mb={2}>
						<NumberInput required isFloat fullWidth label="% Stop PA" {...form.field('stopPAPercentRD')} />
					</Box>
				</Paper>
				<Box mt={1} display="flex" justifyContent="end">
					<SubmitButton icon={false} submitting={form.isSubmitting} success={form.success}>
						<Box mr={1.5} mt={1}> <SendIcon /> </Box>
						Envoyer
					</SubmitButton>
				</Box>
			</Box>}
	</form>
}

export default ObjectivesForm;