export type AssociationStats = {
	id: string;
	name: string;
	imagePath: string;
	runningMissions: number;
	streetHours: number;
	paidHours: number;
	absence: number;
	unjustifiedAbsence: number;
	delay: number;
	donationsTotalCount: number;
	completedDonationsCount: number;
	completedDonationsOver25Count: number;
	donationsTotalAmout: number;
	donationsTotalStopPA?: number;
	averageDonation: number;
	averageAge: number;
	averageStopPA: number;
	under25StopPA: number;
	between18And20Count: number;
	between18And30Count: number;
	femaleCount: number;
	maleCount: number;
	otherGenderCount: number;
	sentDonations: number;
	medianAge: number;
	medianDonation: number;
	activeRecruitersCount: number;
	usedTickets: number;
	usedPaperTickets: number;
	perDiem: number;
	routesDuration: number;
	driver: boolean;
	routes: number;
	exposeName025: string;
	expose025: boolean;
	expose050: boolean;
};

function clean<T extends Object>(o: T): T {
	for (let [k, v] of Object.entries(o)) {
		if (Number.isNaN(v) || v === 'NaN') o[k] = 0;
		else if (v === Infinity || v === 'Infinity') o[k] = '∞';
	}
	return o;
}

export const roundDecimal = (v: any, e: number) => Math.round(parseFloat(v) * 10 ** e) / 10 ** e;

export const calculateStats = (stats: AssociationStats) => {
	return clean({
		...stats,
		transformationRate: Math.round((stats.completedDonationsCount / stats.donationsTotalCount) * 100),
		streetRate: (stats.donationsTotalCount / stats.streetHours).toFixed(2),
		realRate: (stats.completedDonationsCount / stats.streetHours).toFixed(2),
		realRateOver25: (stats.completedDonationsOver25Count / stats.streetHours).toFixed(2),
		_18_20_percent: Math.round((stats.between18And20Count / stats.completedDonationsCount) * 100),
		_18_30_percent: Math.round((stats.between18And30Count / stats.completedDonationsCount) * 100),
		_25_plus_percent: Math.round((stats.completedDonationsOver25Count / stats.completedDonationsCount) * 100),
		malePercent: Math.round((stats.maleCount / stats.completedDonationsCount) * 100),
		femalePercent: Math.round((stats.femaleCount / stats.completedDonationsCount) * 100),
		otherGenderPourcent: Math.round((stats.otherGenderCount / stats.completedDonationsCount) * 100),
		streetPaidHoursRatio: Math.round((stats.streetHours / stats.paidHours) * 100),
		averageAge: Math.round(stats.averageAge),
		stopPAPercent: Math.round((stats.donationsTotalStopPA / stats.completedDonationsCount) * 100),
		averageStopPA: roundDecimal(stats.averageStopPA, 2),
		under25StopPA: roundDecimal(stats.under25StopPA, 2),
		averageDonation: roundDecimal(stats.averageDonation, 2),
		medianDonation: roundDecimal(stats.medianDonation, 2),
		completedDonationsCount: stats.completedDonationsCount || 0,
		between18And20Count: stats.between18And20Count || 0,
		donationsTotalAmout: stats.donationsTotalAmout || 0,
		paidHours: stats.paidHours || 0,
		streetHours: stats.streetHours || 0,
		usedTickets: stats.usedTickets || 0,
		usedPaperTickets: stats.usedPaperTickets || 0,
		perDiem: stats.perDiem || 0,
		absence: stats.absence || 0,
		delay: stats.delay || 0,
		routesDuration: stats.routesDuration || 0,
		exposeName: stats.exposeName025 || '',
		exposeType: stats.expose025 ? 'expose025' : stats.expose050 ? 'expose050' : null,
	});
};
