import React from 'react';
import { Box, Typography } from '@material-ui/core';
import useFetch from '../../hooks/useFetch';
import routes from '../../api/routes';
import { Card, CardContainer } from '../Cards';
import { AssociationFiltersDto } from '../../dto/association';
import { AssociationStats, calculateStats } from '../../dto/associationUtils';
import { formatNumber } from '../../utils';
import { MissionDto } from '../../dto/mission';
import { ObjectivesDto } from '../../dto/objectives';
import Title from '../Title';

export const DashboardObjectives = ({
	missionId,
	mission,
	filters,
}: {
	filters: AssociationFiltersDto;
	mission: MissionDto;
	missionId: number;
}) => {
	const [, partialStats] = useFetch<AssociationStats>(
		routes.associations.dashboardStats({}, filters),
		[JSON.stringify(filters)],
	);
	let dashboardStats = calculateStats((partialStats || {}) as any);

	const [, objectives] = useFetch<ObjectivesDto & { errors: any }>(routes.objectives.missions.getObjectives({missionId}, filters));
	let proportion = calculateElaspedProportion(Date.parse(mission?.startDate), Date.parse(mission?.endDate))
	return (
		objectives?.errors ? (
		  <Box></Box>
		) : (
		  <Box>
			<Title mt={3}>Suivi des objectifs</Title>
			<CardContainer display="flex" mt={1}>
			  { objectives?.streetHours ? <ObjectiveNumber name="HR Rue" isPercent number={calculateDiff(dashboardStats.streetHours, objectives.streetHours, proportion).percentage} /> : <></> }
			  { objectives?.completedDonations ? <ObjectiveNumber name="BS Réel" isPercent number={calculateDiff(dashboardStats.completedDonationsCount, objectives.completedDonations, proportion).percentage} /> : <></> }
			  <ObjectiveNumber name="Don moyen" isPercent number={calculateDiff(dashboardStats?.averageDonation, objectives?.averageDonation, proportion).percentage} />
			  <ObjectiveNumber name="Âge médian" isPercent number={calculateDiff(dashboardStats?.medianAge, objectives?.medianAge, proportion).percentage} />
			  <ObjectiveNumber name="%Stop PA" isPercent number={calculateDiff(dashboardStats?.stopPAPercent, objectives?.stopPAPercent, proportion).percentage} />
			</CardContainer>
		  </Box>
		)
	)
};

export const DashboardObjectivesRD = ({
	missionId,
	mission,
	filters,
}: {
	filters: AssociationFiltersDto;
	mission: MissionDto;
	missionId: number;
}) => {
	const [, partialStats] = useFetch<AssociationStats>(
		routes.associations.dashboardStats({}, filters),
		[JSON.stringify(filters)],
	);
	let dashboardStats = calculateStats((partialStats || {}) as any);

	const [, objectives] = useFetch<ObjectivesDto & { errors: any }>(routes.objectives.missions.getObjectives({missionId}, filters));
	let proportion = calculateElaspedProportion(Date.parse(mission?.startDate), Date.parse(mission?.endDate))
	return (
		objectives?.errors ? (
		  <Box></Box>
		) : (
		  <Box>
			<Title mt={3}>Suivi des objectifs</Title>
			<CardContainer display="flex" mt={1}>
			  <ObjectiveNumber name="Taux réel" isPercent number={calculateDiff(dashboardStats?.realRate, objectives?.realRateRD, proportion).percentage} />
			  <ObjectiveNumber name="Don médian" isPercent number={calculateDiff(dashboardStats?.medianDonation, objectives?.medianDonationRD, proportion).percentage} />
			  <ObjectiveNumber name="Âge médian" isPercent number={calculateDiff(dashboardStats?.medianAge, objectives?.medianAgeRD, proportion).percentage} />
			  <ObjectiveNumber name="%18-30" isPercent number={calculateDiff(dashboardStats?._18_30_percent, objectives?.between18And30PercentRD, proportion).percentage} />
			  <ObjectiveNumber name="%Stop PA" isPercent number={calculateDiff(dashboardStats?.stopPAPercent, objectives?.stopPAPercentRD, proportion).percentage} />
			</CardContainer>
		  </Box>
		)
	)
};

const ObjectiveNumber = ({
	name,
	number,
	isPercent
}: {
	name: string
	number: number,
	isPercent?: boolean
}) => {
	let pos = number >= 0;
	return (
		<Card title={name}>
			<Typography style={{ color: pos ? "green" : "red", fontSize: 20, margin: -2 }}>
				{(pos ? "+" : "") + formatNumber(number, 2) + (isPercent ? "%" : "")}
			</Typography>
		</Card>
	);
};


function daysBetween(d1, d2): number {
	return (d2 - d1) / 1000 / 60 / 60 / 24;
}

function calculateDiff(result, target, proportion): { diff: number, percentage: number } {
	let prop_target = target * proportion
	let diff = result - prop_target;
	let percentage = (result / prop_target * 100) - 100;
	return { percentage, diff};
}

function calculateElaspedProportion(start, end): number {
	let length = daysBetween(start, end)
	let elapsed = Math.ceil(daysBetween(start, Date.now()))
	return Math.min(elapsed / length, 1.0)
}